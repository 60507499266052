import React from 'react';
import * as AppActions from './src/actions/AppActions';

const onRouteUpdate = ({ location }) => {
  // console.log('new pathname', location.pathname);
  // insert state change in global store eg. flux here

  AppActions.setLocation(location);
  setTimeout(() => {
    AppActions.endPageTransition();
  }, 500);
};

export {
  onRouteUpdate
}