import AppDispatcher from '../dispatcher/Dispatcher';

export function changeBackgroundColor(color) {
  AppDispatcher.dispatch({
    type: 'CHANGE_BACKGROUNDCOLOR',
    color,
  });
}

export function changeHighlightColor(color) {
  AppDispatcher.dispatch({
    type: 'CHANGE_HIGHLIGHTCOLOR',
    color,
  });
}

export function changeColors(colors) {
  AppDispatcher.dispatch({
    type: 'CHANGE_COLORS',
    colors,
  });
}

export function setPageSpecs(pageSpecs) {
  AppDispatcher.dispatch({
    type: 'SET_PAGESPECS',
    pageSpecs,
  });
}

export function setLocation(loc) {
  AppDispatcher.dispatch({
    type: 'SET_LOCATION',
    loc,
  });
}

export function endPageTransition() {
  AppDispatcher.dispatch({
    type: 'END_PAGE_TRANSITION',
  });
}

export function startPageTransition() {
  AppDispatcher.dispatch({
    type: 'START_PAGE_TRANSITION',
  });
}

export function showMenu() {
  AppDispatcher.dispatch({
    type: 'SHOW_MENU',
  });
}

export function hideMenu() {
  AppDispatcher.dispatch({
    type: 'HIDE_MENU',
  });
}

export function storeImages(images) {
  AppDispatcher.dispatch({
    type: 'STORE_IMAGES',
    images,
  });
}

export function getImages(images) {
  AppDispatcher.dispatch({
    type: 'GET_IMAGES',
    images,
  });
}
